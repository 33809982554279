import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

const getModalText = (i) => {
  switch(i) {
    case 2:
      return "TAF Gordon 7619, AAA 19862125 Sire: EXAR Advance 6879, Dam: TAF Inez 7115"
    case 3:
      return "TAF Dougie 5049, AAA 19788235, Sire: TAF Hashtag 1115, Dam: TAF Pride 5372"
    case 4:
      return "TAF Ashland 1589, Sire: GAR Ashland, Dam: TAF Princess 1253"
    default:
      return "View larger image"
  }

}

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-10 align-self-end">
                <h1 className="text-uppercase text-white font-weight-bold">
                  Annual Thames-Kea Angus Sale
                </h1>
                <p className="text-white-75 font-weight-light mb-5">
                  Saturday, January 16, 2021 at Noon
                </p>
                <hr className="divider my-4" />
              </div>
              <div className="col-lg-8 align-self-baseline">
                <p className="text-white-75 font-weight-light mb-5">
                  Held at Lincoln County Livestock Commission in Brookhaven, MS
                </p>
                <a
                  className="btn btn-primary btn-xl js-scroll-trigger"
                  href="http://www.angus.org/salebooks"
                >
                  View Sale Book
                </a>
              </div>
            </div>
          </div>
        </header>
        <SEO title="Thames-Kea Angus Sale | Thames Angus Farm | Mississippi Angus" />


        <section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              {this.props.data.images.edges.map((image, i) =>
                <div className="col-lg-4 col-sm-6">
                  <a
                    className="portfolio-box"
                    href={`img/sale/${i+1}.jpg`}
                    onClick={this.handlePortfolioClick.bind(this, this.props.data.images.edges.length-i-1)}
                  >
                    <Img
                      fluid={
                        image.node.childImageSharp.fluid
                      }
                    />
                    <div className="portfolio-box-caption">
                      {i < 2 &&
                        <div className="project-category text-white-50">
                          Click To
                        </div>
                      }
                      <div className="project-name">{getModalText(i)}</div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Let's Get In Touch</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-2">
                  We're partering with Kea Angus and selling 40 angus bulls in an afternoon. Also selling registered Angus females and select groups of commercial females. </p><p className="text-muted mb-5"><br />AI Sires:<br /> EXAR Advance • Musgrave 316 Stunner • Sitz Upward 307R • GAR Ashland • Whitestone 18-Million • EF Authentico829 • Baldridge Beast Mode • VAR Foreman • Southern Charm AA 31 • VAR Discovery 2240 • EXAR Monumental 6056B
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                <a href="tel:+16017570673" className="d-block">
                  +1 (601) 757-0673
                </a>
              </div>
              <div className="col-lg-4 mr-auto text-center">
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a className="d-block" href="mailto:jake@thamesangusfarm.com">
                  jake@thamesangusfarm.com
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">Where it's at</h2>
                <hr className="divider light my-4" />
                <p className="text-white-50 mb-4">
                  The sale will be located at the Lincoln County Livestock Commission.
                </p>
                <iframe
                  width="600"
                  height="450"
                  frameBorder="0"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAWlTHiDmVvsczvO7omUE37t1RVxE_V8F8
    &q=Lincoln+County+Livestock,Brookhaven+MS"
                  allowFullScreen
                  style={{ maxWidth: "100%" }}
                ></iframe>

              </div>
            </div>
          </div>
        </section>
        <PortfolioModal
          show={this.state.modalShow}
          onHide={() => this.setModal(false, 0)}
        >
          <PortfolioCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
          />
        </PortfolioModal>
      </Layout>
    )
  }
}

export const imageData = graphql`
  query {
    images: allFile(
      filter: { relativePath: { glob: "sale/*.jpg" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fixed(width: 35, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
